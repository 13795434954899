import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Container, Col, Row } from 'react-grid-system';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
import FramedImage from '@interness/theme-sonora/src/components/FramedImage';
import OpeningHoursWidget from '@interness/web-core/src/components/modules/Widgets/OpeningHoursWidget';
import { Box } from '@chakra-ui/react';
import { RiTeamFill } from 'react-icons/ri';
export const query = graphql`
  query {
    gallery: directusMediaCollection(name: {eq: "geschaeft"}) {
      name
      images: media {
        sort
        file {
          localFile {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const Img = makeShortcode("Img");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <Heading icon={<RiTeamFill mdxType="RiTeamFill" />} mdxType="Heading">Über uns</Heading>
      <Container fluid mdxType="Container">
        <Row mdxType="Row">
          <Col md={6} mdxType="Col">
  <FramedImage borderOnGatsbyImage sx={{
              'img, figure': {
                margin: 0
              },
              '.int-custom .slick-dots li button': {
                backgroundColor: '#333'
              },
              '.int-custom .slick-dots li.slick-active button': {
                backgroundColor: '#333'
              }
            }} mdxType="FramedImage">
    <Carousel mdxType="Carousel">
      {props.data.gallery.images.map((image, i) => {
                  image = image.file;
                  return <figure key={image.localFile.name}>
            <Img fluid={image.localFile.childImageSharp.fluid} alt={image.localFile.name} loading={i === 0 ? 'eager' : 'lazy'} mdxType="Img" />
          </figure>;
                })}
    </Carousel>
  </FramedImage>
            <hr></hr>
          </Col>
          <Col md={6} mdxType="Col">
            <p><strong parentName="p">{`STAUSS UHREN & SCHMUCK`}</strong>{` ist ein Haus mit einer langen Tradition in Rottweil und steht für präzise Arbeit im Uhrmacherhandwerk.`}</p>
            <p>{`Das hochwertige Uhrensortiment namhafter Marken wird durch aktuelle Schmuckkollektionen der angesagtesten Schmuckdesigner aus dem In- und Ausland ergänzt.`}</p>
            <p>{`Der Name STAUSS steht für Handwerksqualität auf höchstem Niveau, Top-Marken im Bereich Uhren und Schmuck sowie einen hervorragenden Service.`}</p>
            <p>{`Wir freuen uns über Ihren Besuch – im Internet und gerne auch persönlich in Rottweil.`}</p>
            <hr></hr>
  <Box maxW='280px' sx={{
              h4: {
                fontWeight: 'bold'
              }
            }} mdxType="Box">
    <OpeningHoursWidget lang={'de'} widget={{
                sort: 0,
                widget_name: 'openingHours',
                position: 'page',
                translations: [{
                  language: 'de',
                  header: 'Öffnungszeiten'
                }]
              }} mdxType="OpeningHoursWidget" />
  </Box>
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <h2 {...{
        "id": "ihre-ansprechpartner"
      }}>{`Ihre Ansprechpartner`}</h2>
      <TeamMembers layout={'grid'} mdxType="TeamMembers" />
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      