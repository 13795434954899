import React   from 'react';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

const RottweilerLayout = ({children}) => {
  return (
    <div style={{backgroundColor: '#000'}}>
      <Wrapper>
        {children}
      </Wrapper>
    </div>
  )
};

export default RottweilerLayout;
