import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from 'gatsby';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import RottweilerLayout from '../components/RottweilerLayout';
export const query = graphql`
  query {
    gallery: directusMediaCollection(name: {eq: "rottweiler"}) {
      name
      images: media {
        sort
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1600, quality: 95) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      thumbnails: media {
        sort
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 600, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Rottweiler",
  "path": "/rottweiler",
  "lang": "de"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = RottweilerLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Rottweiler`}</h1>
    <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} overlayBgColor={'rgba(16,16,16, 0.95)'} thumbnailWidth={'350px'} fromApi mdxType="Lightbox" />



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      